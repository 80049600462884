<template>
  <v-app-bar app height="100">
    <v-app-bar-nav-icon
      @click="drawer()"
      class="primary--text"
    ></v-app-bar-nav-icon>
    <v-container class="py-0 fill-height" fluid>
      <div
        class="mr-3"
        v-if="
          $vuetify.breakpoint.name == 'lg' ||
          $vuetify.breakpoint.name == 'xl' ||
          $vuetify.breakpoint.name == 'md'
        "
      >
        <v-img src="@/assets/gambar/logo-new.png" max-width="200"></v-img>
      </div>

      <div class="mr-3" v-else>
        <v-img src="@/assets/gambar/logo-new.png" max-width="150"></v-img>
      </div>

      <div
        class="mr-10 primary--text"
        v-if="
          $vuetify.breakpoint.name == 'lg' ||
          $vuetify.breakpoint.name == 'xl' ||
          $vuetify.breakpoint.name == 'md'
        "
      >
        <h1>- BKPP POHUWATO</h1>
      </div>
      <div class="mr-10 primary--text" v-else></div>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-3" icon x-large v-bind="attrs" v-on="on">
            <v-icon color="primary"> mdi-bell </v-icon>
          </v-btn>
        </template>
        <span>Notifikasi</span>
      </v-tooltip>

      <v-menu bottom min-width="450" rounded offset-y nudge-bottom="10" v-if="!READ_ASN_ME">
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar size="40" color="primary">
              <v-icon color="white"> mdi-tune-vertical </v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card class="pa-5">
          <v-row dense class="mb-5" align="center" justify="center">
            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-tablet-dashboard </v-icon>
              <div class="mt-n1">
                <small> Dashboard </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-account-plus </v-icon>
              <div class="mt-n1">
                <small> Tambah </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-account-search </v-icon>
              <div class="mt-n1">
                <small> Peremajaan </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-office-building </v-icon>
              <div class="mt-n1">
                <small> Unit </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-vector-triangle </v-icon>
              <div class="mt-n1">
                <small> Pensus </small>
              </div>
            </v-card>
          </v-row>

          <v-row dense align="center" justify="center">
            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-file-document </v-icon>
              <div class="mt-n1">
                <small> DMS </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-cog </v-icon>
              <div class="mt-n1">
                <small> Set Up </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-calendar-month </v-icon>
              <div class="mt-n1">
                <small> Periodik </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-book-search </v-icon>
              <div class="mt-n1">
                <small> Publikasi </small>
              </div>
            </v-card>

            <v-card flat class="text-center pa-1 mr-5" to="/dashboard">
              <v-icon size="50" color="primary"> mdi-finance </v-icon>
              <div class="mt-n1">
                <small> Laporan </small>
              </div>
            </v-card>
          </v-row>
        </v-card>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import { version } from "../../package";

import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import Dmenu from "@/store/menu";

export default {
  data: () => ({
    appVersion: version,

    token: Cookie.get("token"),
    session: "",

    nipBaru: "",
    namaPegawai: "",
    foto: "",

    READ_ASN_ME: false,
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.cek();
    this.getData();
  },

  methods: {
    drawer() {
      Dmenu.commit("toggle", !Dmenu.state.drawer);
    },

    getData() {
      const url =
        process.env.VUE_APP_ASN + "datautama/nip/" + this.session.nipBaru;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.nipBaru = response.data.mapData.DataUtama.nipBaru;
          this.namaPegawai = response.data.mapData.DataUtama.nama;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    logout() {
      Cookie.delete();
      localStorage.clear();
      window.location.href = process.env.VUE_APP_LOGIN;
    },

    edit() {
      window.open("https://account.idasn.pohuwatokab.go.id", "_blank");
    },

    cek() {
      this.READ_ASN_ME = this.session.authorities.includes("READ_ASN_ME");
    },
  },
};
</script>