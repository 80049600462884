<template>
  <v-footer padless>
    <v-col class="text-center" cols="12">
      <v-icon left>mdi-copyright</v-icon> 2023 — <strong>ID-ASN</strong> — v{{
        appVersion
      }}
    </v-col>
  </v-footer>
</template>

<script>
import { version } from "../../package";
export default {
  data: () => ({
    appVersion: version,
  }),

  methods: {},
};
</script>