<template>
  <v-navigation-drawer v-model="drawer" temporary app>
    <v-list-item @click="close()">
      <v-list-item-icon>
        <v-icon>mdi-close</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Tutup </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list color="transparent" dense nav shaped>
      <v-list-item to="/dashboard" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Dashboard </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group prepend-icon="mdi-briefcase-account" no-action v-if="!READ_ASN_ME">
        <template v-slot:activator>
          <v-list-item-title>Profil ASN</v-list-item-title>
        </template>

        <v-list-item link to="/cari/pns">
          <v-list-item-title>Profil PNS</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/cari/pppk">
          <v-list-item-title>Profil PPPK</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider class="my-2"></v-divider>

      <v-list-item class="mt-2" to="/account" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-shield-account-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Ubah Password </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="mt-2" to="/faq" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-frequently-asked-questions</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> FAQ </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item class="mt-2" to="/cari/all" link color="primary" v-if="!READ_ASN_ME">
        <v-list-item-icon>
          <v-icon>mdi-account-search-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Cari </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider></v-divider>

      <v-list-item class="mt-2" link color="primary" @click="logOut()">
        <v-list-item-icon>
          <v-icon>mdi-power</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Logout </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Dmenu from "@/store/menu";

export default {
  computed: {
    drawer: {
      get() {
        return Dmenu.state.drawer;
      },

      set(value) {
        Dmenu.commit("toggle", value);
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    session: "",

    READ_ASN_ME: false,
  }),

  mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.cek();
  },

  methods: {
    close() {
      Dmenu.commit("toggle", !Dmenu.state.drawer);
    },

    logOut() {
      Cookie.delete();
      localStorage.clear();
      window.location.href = process.env.VUE_APP_LOGIN;
    },

    edit() {
      window.open("https://account.idasn.pohuwatokab.go.id", "_blank");
    },

    cek() {
      this.READ_ASN_ME = this.session.authorities.includes("READ_ASN_ME");
    },
  },
};
</script>
