import Vue from 'vue'
import Cookie from '@/helper/cookie.js'

import VueRouter from 'vue-router'
import Main from '../layout/main.vue'

Vue.use(VueRouter)

const routes = [{
    // catch all 404 - define at the very end
    path: "*",
    name: 'notfound',
    component: () => import( /* webpackChunkName: "notfound" */ '../layout/notfound.vue')
  },

  {
    path: '/auth',
    component: () => import(
      /* webpackChunkName: "auth" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      "../views/Auth"
    ),
    meta: {
      title: 'idasn'
    },
  },

  // CETAK
  {
    path: '/riwayathidup/print/:id',
    meta: {
      early_check: true
    },
    component: () => import( /* webpackChunkName: "riwayathidup" */ '../components/riwayathidup/print.vue')
  },

  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      title: 'idasn',
      requiresAuth: true
    },
    children: [{
        path: '/account',
        name: 'account',
        component: () => import( /* webpackChunkName: "account" */ '../views/Password.vue')
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import( /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
      },

      // CARI ASN
      {
        path: '/cari/all',
        name: 'cari_all',
        meta: {
          CRUD_ADMINISTRATOR: true
        },
        component: () => import( /* webpackChunkName: "cari" */ '../views/cari/all.vue')
      },
      {
        path: '/cari/pns',
        name: 'cari_pns',
        component: () => import( /* webpackChunkName: "cari" */ '../views/cari/pns.vue')
      },
      {
        path: '/cari/pppk',
        name: 'cari_pppk',
        component: () => import( /* webpackChunkName: "cari" */ '../views/cari/pppk.vue')
      },

      // RIWAYAT HIDUP
      {
        path: '/riwayathidup/:id',
        meta: {
          early_check: true
        },
        component: () => import( /* webpackChunkName: "riwayathidup" */ '../views/Riwayathidup.vue')
      },

      // PNS
      {
        path: '/pns',
        name: 'pns',
        component: () => import( /* webpackChunkName: "pns" */ '../views/PNS.vue'),
        children: [{
          path: '/pns/:id',
          name: 'pns_data',
          component: () => import( /* webpackChunkName: "pns_data" */ '../components/asn/dashboard/pns.vue'),
          children: [
            // POSISI DAN JABATAN
            {
              path: '/pns/posjab/:id',
              name: 'pns_posjab',
              component: () => import( /* webpackChunkName: "pns_posjab" */ '../components/asn/profil/rwposjab/data')
            },
            // DATA UTAMA
            {
              path: '/pns/datapribadi/:id',
              name: 'pns_datapribadi',
              component: () => import( /* webpackChunkName: "pns_datapribadi" */ '../components/asn/profil/datautama/tabs')
            },
            {
              path: '/pns/pasangan/:id',
              name: 'pns_pasangan',
              component: () => import( /* webpackChunkName: "pns_pasangan" */ '../components/asn/profil/rwpasangan/Tabs')
            },
            {
              path: '/pns/anak/:id',
              name: 'pns_anak',
              component: () => import( /* webpackChunkName: "pns_anak" */ '../components/asn/profil/rwanak/Tabs')
            },
            {
              path: '/pns/orangtua/:id',
              name: 'pns_orangtua',
              component: () => import( /* webpackChunkName: "pns_orangtua" */ '../components/asn/profil/rworangtua/rworangtua')
            },
            {
              path: '/pns/saudara/:id',
              name: 'pns_saudara',
              component: () => import( /* webpackChunkName: "pns_saudara" */ '../components/asn/profil/rwsaudara/rwsaudara')
            },
            {
              path: '/pns/databadan/:id',
              name: 'pns_databadan',
              component: () => import( /* webpackChunkName: "pns_databadan" */ '../components/asn/profil/databadan/databadan')
            },
            {
              path: '/pns/dokpendukung/:id',
              name: 'pns_dokpendukung',
              component: () => import( /* webpackChunkName: "pns_dokpendukung" */ '../components/asn/profil/dokpendukung/dokpendukung')
            },
            {
              path: '/pns/doktambahan/:id',
              name: 'pns_doktambahan',
              component: () => import( /* webpackChunkName: "pns_doktambahan" */ '../components/asn/profil/doktambahan/doktambahan')
            },

            // DATA RIWAYAT
            {
              path: '/pns/jabatan/:id',
              name: 'pns_jabatan',
              component: () => import( /* webpackChunkName: "pns_jabatan" */ '../components/asn/profil/rwjabatan/Tabs')
            },
            {
              path: '/pns/golongan/:id',
              name: 'pns_golongan',
              component: () => import( /* webpackChunkName: "pns_golongan" */ '../components/asn/profil/rwpangkat/Tabs')
            },
            {
              path: '/pns/pendidikan/:id',
              name: 'pns_pendidikan',
              component: () => import( /* webpackChunkName: "pns_pendidikan" */ '../components/asn/profil/rwpendidikan/Tabs')
            },
            {
              path: '/pns/gaji/:id',
              name: 'pns_gaji',
              component: () => import( /* webpackChunkName: "pns_gaji" */ '../components/asn/profil/rwgaji/Tabs')
            },
            {
              path: '/pns/cuti/:id',
              name: 'pns_cuti',
              component: () => import( /* webpackChunkName: "pns_cuti" */ '../components/asn/profil/rwcuti/Tabs')
            },
            {
              path: '/pns/penghargaan/:id',
              name: 'pns_penghargaan',
              component: () => import( /* webpackChunkName: "pns_penghargaan" */ '../components/asn/profil/rwpenghargaan/Tabs')
            },
            {
              path: '/pns/organisasi/:id',
              name: 'pns_organisasi',
              component: () => import( /* webpackChunkName: "pns_organisasi" */ '../components/asn/profil/rworganisasi/Tabs')
            },
            {
              path: '/pns/hukdis/:id',
              name: 'pns_hukdis',
              component: () => import( /* webpackChunkName: "pns_hukdis" */ '../components/asn/profil/rwhukdis/Tabs')
            },
            {
              path: '/pns/luarnegeri/:id',
              name: 'pns_luarnegeri',
              component: () => import( /* webpackChunkName: "pns_luarnegeri" */ '../components/asn/profil/rwluarnegeri/Tabs')
            },
            {
              path: '/pns/bahasa/:id',
              name: 'pns_bahasa',
              component: () => import( /* webpackChunkName: "pns_bahasa" */ '../components/asn/profil/rwbahasa/Tabs')
            },

            // DATA KOMPETENSI
            {
              path: '/pns/diklat/:id',
              name: 'pns_diklat',
              component: () => import( /* webpackChunkName: "pns_diklat" */ '../components/asn/profil/rwdiklat/Tabs')
            },
            {
              path: '/pns/kursus/:id',
              name: 'pns_kursus',
              component: () => import( /* webpackChunkName: "pns_kursus" */ '../components/asn/profil/rwkursus/Tabs')
            },
            {
              path: '/pns/kinerja/:id',
              name: 'pns_kinerja',
              component: () => import( /* webpackChunkName: "pns_kinerja" */ '../components/asn/profil/rwskp/Tabs')
            },
          ]
        }, ]
      },

      // PPPK
      {
        path: '/pppk',
        name: 'pppk',
        component: () => import( /* webpackChunkName: "pppk" */ '../views/PPPK.vue'),
        children: [{
          path: '/pppk/:id',
          name: 'pppk_data',
          component: () => import( /* webpackChunkName: "pppk_data" */ '../components/asn/dashboard/pppk.vue'),
          children: [
            // DATA UTAMA
            {
              path: '/pppk/datapribadi/:id',
              name: 'pppk_datapribadi',
              component: () => import( /* webpackChunkName: "pppk_datapribadi" */ '../components/asn/profil/datautama/tabs')
            },
            {
              path: '/pppk/pasangan/:id',
              name: 'pppk_pasangan',
              component: () => import( /* webpackChunkName: "pppk_pasangan" */ '../components/asn/profil/rwpasangan/Tabs')
            },
            {
              path: '/pppk/anak/:id',
              name: 'pppk_anak',
              component: () => import( /* webpackChunkName: "pppk_anak" */ '../components/asn/profil/rwanak/Tabs')
            },
            {
              path: '/pppk/orangtua/:id',
              name: 'pppk_orangtua',
              component: () => import( /* webpackChunkName: "pppk_orangtua" */ '../components/asn/profil/rworangtua/rworangtua')
            },
            {
              path: '/pppk/saudara/:id',
              name: 'pppk_saudara',
              component: () => import( /* webpackChunkName: "pppk_saudara" */ '../components/asn/profil/rwsaudara/rwsaudara')
            },
            {
              path: '/pppk/databadan/:id',
              name: 'pppk_databadan',
              component: () => import( /* webpackChunkName: "pppk_databadan" */ '../components/asn/profil/databadan/databadan')
            },
            {
              path: '/pppk/dokpendukung/:id',
              name: 'pppk_dokpendukung',
              component: () => import( /* webpackChunkName: "pppk_dokpendukung" */ '../components/asn/profil/dokpendukung/dokpendukung')
            },
            {
              path: '/pppk/doktambahan/:id',
              name: 'pppk_doktambahan',
              component: () => import( /* webpackChunkName: "pppk_doktambahan" */ '../components/asn/profil/doktambahan/doktambahan')
            },

            // DATA RIWAYAT
            {
              path: '/pppk/pengangkatan/:id',
              name: 'pppk_pengangkatan',
              component: () => import( /* webpackChunkName: "pppk_pengangkatan" */ '../components/asn/profil/pppk/rwpengangkatan/Tabs')
            },
            {
              path: '/pppk/perker/:id',
              name: 'pppk_perker',
              component: () => import( /* webpackChunkName: "pppk_perker" */ '../components/asn/profil/pppk/rwperker/Tabs')
            },
            {
              path: '/pppk/pendidikan/:id',
              name: 'pppk_pendidikan',
              component: () => import( /* webpackChunkName: "pppk_pendidikan" */ '../components/asn/profil/rwpendidikan/Tabs')
            },
            {
              path: '/pppk/gaji/:id',
              name: 'pppk_gaji',
              component: () => import( /* webpackChunkName: "pppk_gaji" */ '../components/asn/profil/rwgaji/Tabs')
            },
            {
              path: '/pppk/cuti/:id',
              name: 'pppk_cuti',
              component: () => import( /* webpackChunkName: "pppk_cuti" */ '../components/asn/profil/rwcuti/Tabs')
            },
            {
              path: '/pppk/penghargaan/:id',
              name: 'pppk_penghargaan',
              component: () => import( /* webpackChunkName: "pppk_penghargaan" */ '../components/asn/profil/rwpenghargaan/Tabs')
            },
            {
              path: '/pppk/organisasi/:id',
              name: 'pppk_organisasi',
              component: () => import( /* webpackChunkName: "pppk_organisasi" */ '../components/asn/profil/rworganisasi/Tabs')
            },
            {
              path: '/pppk/hukdis/:id',
              name: 'pppk_hukdis',
              component: () => import( /* webpackChunkName: "pppk_hukdis" */ '../components/asn/profil/rwhukdis/Tabs')
            },
            {
              path: '/pppk/luarnegeri/:id',
              name: 'pppk_luarnegeri',
              component: () => import( /* webpackChunkName: "pppk_luarnegeri" */ '../components/asn/profil/rwluarnegeri/Tabs')
            },
            {
              path: '/pppk/bahasa/:id',
              name: 'pppk_bahasa',
              component: () => import( /* webpackChunkName: "pppk_bahasa" */ '../components/asn/profil/rwbahasa/Tabs')
            },

            // DATA KOMPETENSI
            {
              path: '/pppk/diklat/:id',
              name: 'pppk_diklat',
              component: () => import( /* webpackChunkName: "pppk_diklat" */ '../components/asn/profil/rwdiklat/Tabs')
            },
            {
              path: '/pppk/kursus/:id',
              name: 'pppk_kursus',
              component: () => import( /* webpackChunkName: "pppk_kursus" */ '../components/asn/profil/rwkursus/Tabs')
            },
            {
              path: '/pppk/kinerja/:id',
              name: 'pppk_kinerja',
              component: () => import( /* webpackChunkName: "pppk_kinerja" */ '../components/asn/profil/rwskp/Tabs')
            },
          ]
        }]
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let myCookie = Cookie.get('myCookie');
  let token = Cookie.get('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!myCookie || !token) {
      next({
        path: '/auth',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      const session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      let arr = session.authorities
      if (to.matched.some(record => record.meta.early_check)) {
        if (arr.length > 0) {
          next()
        } else {
          next({
            name: 'dashboard'
          })
        }
      } else if (to.matched.some(record => record.meta.READ_ASN_ALL_OPD)) {
        if (arr.some((item) => item === 'READ_ASN_ALL_OPD')) {
          next()
        } else {
          next({
            name: 'dashboard'
          })
        }
      } else if (to.matched.some(record => record.meta.READ_ASN_GROUP_OPD)) {
        if (arr.some((item) => item === 'READ_ASN_GROUP_OPD')) {
          next()
        } else {
          next({
            name: 'dashboard'
          })
        }
      } else if (to.matched.some(record => record.meta.CRUD_ADMINISTRATOR)) {
        if (arr.some((item) => item === 'CRUD_ADMINISTRATOR')) {
          next()
        } else {
          next({
            name: 'dashboard'
          })
        }
      } else {
        next()
      }

    }
  } else {
    next()
  }
})

export default router