<template>
  <v-app id="inspire">
    <CMenu />
    <CHeader />

    <v-main
      class="grey lighten-3 mt-5 px-10 pb-10"
      v-if="
        $vuetify.breakpoint.name == 'lg' ||
        $vuetify.breakpoint.name == 'xl' ||
        $vuetify.breakpoint.name == 'md'
      "
    >
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-main class="grey lighten-3 mt-5 pb-10" v-else>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <CFooter />
  </v-app>
</template>

<script>
import CHeader from "@/layout/header";
import CMenu from "@/layout/menu";
import CFooter from "@/layout/footer";

export default {
  components: {
    CHeader,
    CMenu,
    CFooter,
  },

  data: () => ({}),

  computed: {
    firstSegment: function () {
      const str = this.$route.path;
      const firstSegment = str.split("/")[1];

      return firstSegment;
    },

    secondSegment: function () {
      const str = this.$route.path;
      const secondSegment = str.split("/")[2];

      return secondSegment;
    },
  },

  mounted() {
    if (this.$route.path === "/") {
      this.$router.push("/dashboard").catch(() => {});
    }
  },
};
</script>